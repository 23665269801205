<script setup lang="ts">
import type { Itinerary } from '~/models/Tender'

defineProps({
  itineraries: {
    type: Array<Itinerary>,
    required: true,
  },
})

const getPortName = (itinerary: Itinerary) => {
  if (itinerary.port && itinerary.port.place) {
    return itinerary.port.place.name
  }
  else {
    return itinerary.name
  }
}
</script>

<template>
  <div class="itineraries">
    <div
      v-for="itinerary in itineraries"
      :id="itinerary.uniqueId"
      :key="itinerary.uniqueId"
      class="itinerary"
    >
      <div class="left-side">
        <UiIconsPosition />
      </div>
      <div class="right-side">
        <div class="detail">
          <div class="left">
            <span class="day">{{ $t('cruises.day') }} {{ itinerary.day }}</span>
            <span class="name notranslate">{{ getPortName(itinerary) }}</span>
          </div>
          <div
            v-if="itinerary.departureTime && itinerary.departureTime !== '00:00' || itinerary.arrivalTime && itinerary.arrivalTime !== '00:00'"
            class="right"
          >
            <UiIconsCloak />
            <div>
              <span>{{ $t('cruises.modal.arrival') }} {{ itinerary.arrivalTime }}</span>
              <span> - </span>
              <span>{{ $t('cruises.modal.departure') }} {{ itinerary.departureTime }}</span>
            </div>
          </div>
        </div>
        <div
          class="visual"
        >
          <NuxtImg
            format="webp"
            loading="lazy"
            width="530"
            v-if="itinerary.visual"
            :src="itinerary.visual"
            :alt="itinerary.name"
          />
          <div
            v-else
            class="visual-helm"
          >
            <UiIconsHelm
              :alt="itinerary.name"
            />
          </div>
        </div>
        <div
          class="description"
          v-html="itinerary.description"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.itineraries {
  display: flex;
  flex-direction: column;
  gap: $space-md;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    gap: $space-lg;
    padding-top: $space-lg;
  }

  .itinerary {
    display: flex;
    gap: $space-sm;

    .left-side {
      display: none;

      @media (min-width: map-get($grid-breakpoints, lg)) {
        display: block;
        padding-top: $space-xs;
        font-size: 1.3rem;
        position: relative;

        &:after {
          content: ' ';
          display: block;
          width: 1px;
          height: 95%;
          background-color: var(--color-navy-10);
          position: absolute;
          transform: translateX(-50%);
          top: 2.2rem;
          left: 50%;
        }
      }
    }

    .right-side {
      width: 100%;

      .detail {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: $space-sm;

        .left {
          display: flex;
          flex-direction: column;

          .day {
            font-size: 0.8rem;
            text-transform: uppercase;
          }

          .name {
            font-size: 1.2rem;
          }
        }

        .right {
          display: flex;
          align-items: center;
          gap: $space-xs;

          > div {
            display: flex;
            flex-direction: column;
            text-align: center;

            @media (min-width: map-get($grid-breakpoints, md)) {
              flex-direction: row;
              gap: 0.5rem;
            }
          }
        }
      }

      .visual {
        position: relative;
        padding-bottom: 250px;
        margin: 0 0 $space-sm 0;

        @media (min-width: map-get($grid-breakpoints, lg)) {
          padding-bottom: 0;
        }

        img {
          position: absolute;
          height: 100%;
          object-fit: cover;
          width: 100%;
          display: block;
          object-position: 10%;

          @media (min-width: map-get($grid-breakpoints, lg)) {
            position: unset;
          }
        }

        .visual-helm {
          height: 250px;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }

      .description {
        p {
          margin: 0;
        }
      }
    }
  }
}
</style>
